<template>
    <default-template
        :title="'温熱・省エネ設備機器ポータルサイト' + title"
        :is-visible-public="false"
        :is-visible-logout="false"
        :is-visible-pass-update="false"
        :is-visible-jigyosya-login="false"
        :is-house="isHouse"
    >
        <div class="bl_contents_wrapper">
            <div v-if="isHouse">
                <h3 class="el_lv3Heading">温熱・省エネ設備機器等ポータルサイト（住宅版）に掲載をご希望の事業者様へ</h3>
                <p>このサイトは、低炭素認定住宅部品等を、設計者等利用者が一括して検索、各事業者の案内ページへ遷移できるポータルサイトです。</p>
                <h4 class="el_lv4Heading">入力内容をご確認ください。</h4>
                <p>
                    入力内容をご確認の上、間違いなければ「上記の内容で送信」ボタンを押してください。<br>
                    変更したい項目がある場合は、「戻る」ボタンを押してください。<br>
                    送信後、ご入力のメールアドレスに、ご案内のメールが届きます。
                </p>
            </div>
            <div v-else>
                <h3 class="el_lv3Heading">温熱・省エネ設備機器等ポータルサイト（非住宅）に掲載をご希望の事業者様へ</h3>
                <h4 class="el_lv4Heading">入力内容をご確認ください。</h4>
                <p>
                    入力内容をご確認の上、間違いなければ「上記の内容で送信」ボタンを押してください。<br>
                    変更したい項目がある場合は、「戻る」ボタンを押してください。<br>
                    送信後、ご入力のメールアドレスに、ご案内のメールが届きます。
                </p>
            </div>
            <div class="bl_formWrapper ly_formWrapper">
                <organization-confirm
                    :is-house="isHouse"
                    :is-update="false"
                >
                    <user-confirm />
                </organization-confirm>
                <div class="ly_buttonBox">
                    <app-button
                        name="戻る"
                        @click="cancel"
                        ref="jest-register-confirm-cancel"
                        class="cancel"
                    />
                    <app-button
                        name="上記の内容で送信"
                        @click="submit"
                        ref="jest-register-confirm-submit"
                    />
                </div>
            </div>
        </div>
    </default-template>
</template>

<script>
/**
 * P003 新規事業者申し込み確認
 *
 * [算出プロパティ]
 * title ... アクセスされたページの種類に合わせてタイトルを出し分ける
 *
 * [イベント処理]
 * 「上記の内容で送信」ボタン押下時 ... apiにデータ送信、成功すれば完了画面・失敗すれば申し込み画面へ
 * 「戻る」ボタン押下時 ... 申し込み画面へ遷移
 */
import { mapGetters, mapActions } from 'vuex'
import { move } from '@/util'
import OrganizationConfirm from '@/components/organisms/OrganizationConfirm'
import UserConfirm from '@/components/organisms/UserConfirm'

export default {
    name:'OrganizationRegisterConfirm',
    components:{OrganizationConfirm, UserConfirm},
    computed:{
        ...mapGetters({
            isHouse:'status/isHouse'
        }),
        title(){
            return this.isHouse ? '(住宅版)' : '(非住宅版)'
        }
    },
    methods:{
        ...mapActions({
            register:'register/register',
            clearError:'error/clear',
            updateError:'error/update',
        }),
        submit(){
            this.clearError()
            this.register(this.isHouse)
                .then(() => {
                    move(this.$router, '/organization/register/complete')
                }, reject => {
                    this.updateError(reject.response)
                    move(this.$router, '/organization/register')
                })
        },
        cancel(){
            this.clearError()
            move(this.$router, '/organization/register')
        }
    }
}
</script>

<style scoped>
.bl_contents_wrapper{
    min-width: 100%;
    max-width: 100%;
}
table{
    border-collapse: collapse;
}
th, td {
    border:1px solid #000;
}
.rounded_num {
    counter-reset: chap;
    list-style:none;
}
.rounded_num > li::before {
    counter-increment: chap;
    content:counter(chap);
    display: inline-flex;
    justify-content: center;
    align-items:center;
    width:1em;
    height: 1em;
    font-size:0.9em;
    border-radius:1em;
    border:0.25px solid #000;
    margin-right:0.5em;
}
.ly_formWrapper{
    width: 100%;
}
.ly_form_box {
    width: 100%;
}
.ly_form_box >>> div.common_text_column, .form_box >>> div.formrow {
    grid-template-columns: 17em minmax(300px,1fr);
    -ms-grid-columns: 17em minmax(300px,1fr);
}
.ly_form_box >>> .ly_userForm{
  padding: 16px 0;
  border: none;
}
.ly_form_box >>> .ly_form_box.ly_userForm div.formrow{
  grid-template-columns: 17em minmax(300px,1fr);
  -ms-grid-columns: 17em minmax(300px,1fr);
}
</style>