<template>
    <default-template
        :title="'温熱・省エネ設備機器ポータルサイト' + title"
        :is-visible-public="false"
        :is-visible-logout="false"
        :is-visible-pass-update="false"
        :is-visible-jigyosya-login="false"
        :is-house="isHouse"
    >
        <div class="bl_contents_wrapper">
            <div v-if="isHouse">
                <h3 class="el_lv3Heading">温熱・省エネ設備機器等ポータルサイト（住宅版）に掲載をご希望の事業者様へ</h3>
                <div class="bl_msgBox">
                    <p>
                        登録が完了しました。<br>登録されたメールアドレスにメールを送信しましたのでご確認ください。
                    </p>
                </div>
            </div>
            <div v-else>
                <h3>温熱・省エネ設備機器等ポータルサイト（非住宅）に掲載をご希望の事業者様へ</h3>
                <div class="bl_msgBox">
                    <p>
                        登録が完了しました。<br>
                        ご入力のメールアドレスに、ご案内のメールが届きます。
                    </p>
                </div>
            </div>
            <div class="ly_buttonBox">
                <app-button
                    name="終了する"
                    @click="end"
                    ref="jest-register-complete-end"
                />
            </div>
        </div>
    </default-template>
</template>

<script>
/**
 * P004 新規事業者申し込み完了
 *
 * [算出プロパティ]
 * title ... アクセスされたページの種類に合わせてタイトルを出し分ける
 *
 * [イベント処理]
 * 「終了する」ボタン押下時 ... アクセスされたページの種類に合わせたログインページに遷移
 */
import { mapGetters } from 'vuex'
import { move } from '@/util'

export default {
    name:'OrganizationRegisterComplete',
    computed:{
        ...mapGetters({
            isHouse:'status/isHouse'
        }),
        title(){
            return this.isHouse ? '(住宅版)' : '(非住宅版)'
        }
    },
    methods:{
        end(){
            const path = this.isHouse ? '/login/house' : '/login/building'
            move(this.$router, path)
        }
    }
}
</script>

<style scoped>
.bl_contents_wrapper{
    min-width: 100%;
    max-width: 100%;
}
table{
    border-collapse: collapse;
}
th, td {
    border:1px solid #000;
}
.rounded_num {
    counter-reset: chap;
    list-style:none;
}
.rounded_num > li::before {
    counter-increment: chap;
    content:counter(chap);
    display: inline-flex;
    justify-content: center;
    align-items:center;
    width:1em;
    height: 1em;
    font-size:0.9em;
    border-radius:1em;
    border:0.25px solid #000;
    margin-right:0.5em;
}
</style>