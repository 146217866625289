<template>
    <default-template
        :title="'温熱・省エネ設備機器ポータルサイト' + title"
        :is-visible-public="false"
        :is-visible-logout="false"
        :is-visible-pass-update="false"
        :is-visible-jigyosya-login="false"
        :is-house="isHouse"
    >
        <div class="bl_contents_wrapper">
            <div v-if="isHouse">
                <h3 class="el_lv3Heading">温熱・省エネ設備機器等ポータルサイト（住宅版）に掲載をご希望の事業者様へ</h3>
                <p>
                    このサイトは、低炭素認定住宅部品等を、設計者等利用者が一括して検索、各事業者の案内ページへ遷移できるポータルサイトです。<br>
                    このサイトに、情報を掲載ご希望の事業者様は、以下のフォームより必要項目を入力し、「入力内容の確認」→「上記の内容で送信する」を行ってください。<br>
                    その後、ご入力のメールアドレスに、ご案内のメールが届きます。<br>
                </p>
            </div>
            <div v-else>
                <h3 class="el_lv3Heading">温熱・省エネ設備機器等ポータルサイト（非住宅）に掲載をご希望の事業者様へ</h3>
                <p>
                    以下のフォームより必要項目を入力し、「入力内容の確認」→「上記の内容で送信する」を行ってください。<br>
                    その後、ご入力のメールアドレスに、ご案内のメールが届きます。<br>
                </p>
            </div>
            <error-list />
            <div class="bl_formWrapper ly_formWrapper">
                <organization-form
                    :is-house="isHouse"
                    :is-update="false"
                >
                    <user-form />
                </organization-form>
                <div class="ly_buttonBox">
                    <app-button
                        name="キャンセル"
                        @click="cancel"
                        ref="jest-register-cancel"
                        class="cancel"
                    />
                    <app-button
                        name="入力内容確認"
                        @click="submit"
                        ref="jest-register-submit"
                    />
                </div>
            </div>
        </div>
    </default-template>
</template>

<script>
/**
 * P002 新規事業者申し込み
 *
 * [算出プロパティ]
 * title ... アクセスされたページの種類に合わせてタイトルを出し分ける
 *
 * [イベント処理]
 * 「入力内容確認」ボタン押下時 ... メールアドレスとパスワードがそれぞれ確認用データと一致しているかを確認し、一致していればP003 新規事業者申し込み確認へ
 *                                  一致していなければStoreからエラー取得して表示
 * 「戻る」ボタン押下時 ... 新規事業者案内ページに遷移
 */
import { mapGetters, mapActions } from 'vuex'
import { move } from '@/util'
import ErrorList from '@/components/molecules/ErrorList'
import OrganizationForm from '@/components/organisms/OrganizationForm'
import UserForm from '@/components/organisms/UserForm'

export default {
    name:'OrganizationRegister',
    components:{ErrorList, OrganizationForm, UserForm},
    computed:{
        ...mapGetters({
            isHouse:'status/isHouse',
            prevalidate:'register/user/prevalidate',
            prevalidateError:'register/user/prevalidateError',
            organizationPrevalidate:'register/organization/prevalidate',
            organizationPrevalidateError:'register/organization/prevalidateError'
        }),
        title(){
            return this.isHouse ? '(住宅版)' : '(非住宅版)'
        }
    },
    mounted(){
        scroll(0, 0)
        if(document.getElementById('organization_name') != null){
            document.getElementById('organization_name').focus()
        }
    },
    methods:{
        ...mapActions({
            clearError:'error/clear',
            updateError:'error/update',
        }),
        submit(){
            this.clearError()
            if(this.prevalidate && this.organizationPrevalidate) {
                move(this.$router, '/organization/register/confirm')
            } else {
                this.updateError({
                    status:422,
                    data:{
                        errors:Object.assign(this.prevalidateError, this.organizationPrevalidateError)
                    }
                })
            }
        },
        cancel(){
            this.clearError()
            move(this.$router, '/organization/guide')
        }
    }
}
</script>

<style scoped>
.bl_contents_wrapper{
    min-width: 100%;
    max-width: 100%;
}
table{
    border-collapse: collapse;
}
th, td {
    border:1px solid #000;
}
.rounded_num {
    counter-reset: chap;
    list-style:none;
}
.rounded_num > li::before {
    counter-increment: chap;
    content:counter(chap);
    display: inline-flex;
    justify-content: center;
    align-items:center;
    width:1em;
    height: 1em;
    font-size:0.9em;
    border-radius:1em;
    border:0.25px solid #000;
    margin-right:0.5em;
}
.bl_formWrapper {
    width: 100%;
}
.bl_formWrapper >>> .ly_form_box{
    width: 100%;
}
</style>